.HeaderMain {
  width: 100%;
  height: 124px;
  background: #252525 url('../../images/header-background.png') no-repeat;
  background-size: cover;
  padding: 0 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  @media (max-width: 1366px) {
   height: 80px;
  }
  @media (max-width: 992px) {
   padding: 0 20px;
  }
  @media (max-width: 767px) {
   padding: 0 20px;
  }
 .BrandLogo {
   max-width: 96px;
   @media (max-width: 1366px) {
    width: 146px;
   }
  }
  .BrandTagline {
    @media (max-width: 767px) {
      display: none;
    }
  }
  h6 {
   color: #fff;
   margin-top: 5px;
   font-size: 16px;
    @media (max-width: 767px) {
     font-size: 14px;
    }
  }
  .ProfileImage {
   width: 44px;
   height: 44px;
   object-fit: cover;
   border-radius: 50%;
   border: 2px solid #FFE144;
   margin-right: 14px;
    @media (max-width: 767px) {
     margin-right: 0;
    }
  }
  .ProfileText {
    @media (max-width: 767px) {
     display: none;
    }
  }
  .dropdown-menu {
   min-width: 214px !important;
   background: #252525 !important;
   margin-top: 45px !important;
   position: relative;
   @media (max-width: 767px) {
     margin-top: 25px !important;
    }
   &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid #252525;
    position: absolute;
    top: -10px;
    right: 20px;
   }
  }
  .dropdown-toggle {
   background: none !important;
   border: none !important;
   display: flex;
   text-align: left;
   min-width: 200px;
    @media (max-width: 767px) {
      min-width: 50px;
    }
   &:focus {
    outline: none;
    box-shadow: none;
   }
   &::after {
    content: '';
    display: none;
   }
    h5 {
     font-weight: 900;
     font-size: 16px;
     line-height: 20px;
     color: #fff;
     margin-right: 10px;
    }
    h6 {
     font-size: 12px;
     line-height: 18px;
     color: #fff;
     margin-right: 10px;
    }
    &.show {
      .dropdown-toggle-arrow {
        bottom: 2px;
        &::after {
          transform: rotate(90deg) translate(10px, 0);
        }
        &::before {
          transform: translate(10px, 0);
        }
      }
    }
  }
  .dropdown-toggle-arrow {
   position: relative;
   width: 13px;
   height: 13px;
   display: inline-block;
   position: relative;
   bottom: -5px;
   left: 10px;
   transition: 0.4s ease;
   margin-top: 2px;
   text-align: left;
   transform: rotate(45deg);
   &::after,
   &::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 12px;
    height: 3px;
    background-color: #fff;
    transition: 0.4s ease;
   }
    &::after {
     content: "";
     position: absolute;
     transform: rotate(90deg);
     top: -5px;
     left: 5px;
    }
  }
  .dropdown-item {
    display: flex;
    justify-content: space-between;
    color: #fff;
    padding: 8px 16px;
    &:focus,
    &:hover {
     background: #000 !important;
    }
    img {
     width: 20px;
     height: 20px;
     object-fit: contain;
     text-align: right;
    }
  }
}
