.taggrHeaderMain {
  width: 100%;
  height: 100vh;
  background: #252525 url('../../images/hive-animation.gif') no-repeat;
  background-size: cover;
  padding: 0 60px;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: -99;
}

.taggrNoticeLogo {
  margin: 20px;
  h6 {
    color: #fff;
    margin-top: 5px;
    font-size: 16px;
     @media (max-width: 767px) {
      font-size: 14px;
     }
   }
}