.CitationsWapper {
  padding: 124px 0 20px 0;

  @media (max-width:768px) {
    padding: 80px 0 20px 0 !important;
  }

  .TopHeader {
    width: 100%;
    padding: 20px 60px;
    border-bottom: 1px solid #ccc;

    @media (max-width:768px) {
      padding: 20px;
    }

    h3 {
      color: #1E1E1E;
      font-weight: 700;
      font-size: 24px;
      margin: 0;
      padding: 0;
    }

    .BackButton {
      width: 8px;
      margin-right: 10px;
    }
  }

  .FormBg {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    padding: 40px 0;

    .InputLabels {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #1E1E1E;
      margin-bottom: 10px;
      width: 100%;
    }

    .InputStyles {
      box-sizing: border-box;
      width: 100%;
      height: 58px;
      border: 1px solid #C1BBBB;
      border-radius: 6px;
      font-weight: 500;
      font-size: 16px;
      line-height: normal;
      text-align: left;
      color: #1E1E1E;
      padding: 0 14px;

      &:focus {
        outline: none;
      }
    }

    .SelectStyles {
      box-sizing: border-box;
      width: 100%;
      height: 58px;
      border: 1px solid #C1BBBB;
      border-radius: 6px;
      font-weight: 500;
      font-size: 16px;
      line-height: normal;
      text-align: left;
      color: #1E1E1E;
      padding: 0 14px;
      background: url('../../images/ic-down-arrow.svg') no-repeat;
      background-position: calc(100% - 16px) center;
      background-size: 14px;
      appearance: none;
      -webkit-appearance: none;
      -o-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;

      &:focus {
        outline: none;
      }
    }

    .TextareaStyles {
      box-sizing: border-box;
      width: 100%;
      height: 168px;
      border: 1px solid #C1BBBB;
      border-radius: 6px;
      font-weight: 500;
      font-size: 16px;
      line-height: normal;
      text-align: left;
      color: #1E1E1E;
      padding: 10px 14px;
      resize: none;

      &:focus {
        outline: none;
      }
    }

    .BlackBtn {
      width: 220px;
      height: 55px;
      background: #000000;
      border: 1px solid #000000;
      border-radius: 6px;
      font-weight: 600;
      font-size: 14px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      text-align: center;
      color: #FFFFFF;
    }

    .CheckboxList {
      display: flex;
      flex-wrap: wrap;

      li {
        margin-right: 20px;
      }
    }

    .CheckboxList .form-group input {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
    }

    .CheckboxList .form-group label {
      position: relative;
      cursor: pointer;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #1E1E1E;
    }

    .CheckboxList .form-group label:before {
      content: '';
      -webkit-appearance: none;
      background-color: #ddd;
      border: 1px solid #ddd;
      padding: 8px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 5px;
      border-radius: 3px;
      margin-top: -2px;
    }

    .CheckboxList .form-group input:checked+label:before {
      content: '';
      background-color: #2F9E3A;
      border: 1px solid #2F9E3A;
      border-radius: 3px;
    }

    .CheckboxList .form-group input:checked+label:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 7px;
      width: 5px;
      height: 11px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }


  }
}



        .InformWrapper {
          width: 100%;
          padding: 20px 60px;
    
          @media (max-width: 1024px) {
            padding: 20px;
          }
    
          .FormListTitle {
            font-weight: 800;
            font-size: 18px;
            line-height: 22px;
            color: #1E1E1E;
            margin: 0;
            padding: 20px 0;
            display: inline-flex;
            align-items: center;
    
            img {
              width: auto;
              height: 18px;
              margin-right: 10px;
            }
          }
    
          .FormList {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -20px 20px -20px;
    
            li {
              width: 20%;
              padding: 0 20px;
              margin-bottom: 0;
    
              @media (max-width: 1024px) {
                width: 33.333%;
              }
    
              @media (max-width: 980px) {
                width: 50%;
              }
    
              @media (max-width: 767px) {
                width: 100%;
              }
    
              label {
                width: 100%;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: #1E1E1E;
                margin-bottom: 10px;
              }
    
              .InputStyles {
                width: 100%;
                height: 46px;
                border: 1px solid #EEEEEE;
                border-radius: 4px;
                background-color: #EEEEEE;
                padding: 0 12px;
                color: #777777;
              }
            }
          }
    
          .ImageList {
            display: flex;
            flex-wrap: wrap;
            margin: 0 0 20px 0;
    
            li {
              width: 250px;
              margin-right: 24px;
              border-radius: 8px;
              padding-top: 8%;
              position: relative;
              margin-bottom: 20px;
    
              @media (max-width: 1024px) {
                padding-top: 15%;
              }
    
              @media (max-width: 768px) {
                padding-top: 18%;
                width: 30%;
              }
    
              @media (max-width: 767px) {
                width: 100%;
                padding-top: 58%;
                margin-right: 0;
              }
    
              img {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                object-fit: cover;
                border-radius: 8px;
              }
            }
          }
    
          .WhiteBtn {
            width: 170px;
            height: 55px;
            background: #fff;
            border: 1px solid #000000;
            border-radius: 5px;
            font-weight: 600;
            font-size: 16px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            text-align: center;
            color: #000;
          }
        }

        
.PaymentWrap {

 .InputLabels {
   font-weight: 600;
   font-size: 16px;
   line-height: 19px;
   color: #1E1E1E;
   margin-bottom: 10px;
   width: 100%;
   display: block;
  }
 
  .InputCard {
   background: url('../../images/ic-visa.svg') no-repeat;
   background-position: calc(100% - 16px) center;
   background-size: 70px;
  }
  .InputCVC {
   background: url('../../images/ic-cvc.svg') no-repeat;
   background-position: calc(100% - 16px) center;
   background-size: 30px;
  }
  .InputStyles {
   box-sizing: border-box;
   width: 100%;
   height: 58px;
   border: 1px solid #C1BBBB;
   border-radius: 6px;
   font-weight: 500;
   font-size: 16px;
   line-height: normal;
   text-align: left;
   color: #1E1E1E;
   padding: 0 14px;
 
   &:focus {
    outline: none;
   }
  }
 
  .SelectStyles {
   box-sizing: border-box;
   width: 100%;
   height: 58px;
   border: 1px solid #C1BBBB;
   border-radius: 6px;
   font-weight: 500;
   font-size: 16px;
   line-height: normal;
   text-align: left;
   color: #1E1E1E;
   padding: 0 14px;
   background: url('../../images/ic-down-arrow.svg') no-repeat;
   background-position: calc(100% - 16px) center;
   background-size: 14px;
   appearance: none;
   -webkit-appearance: none;
   -o-appearance: none;
   -moz-appearance: none;
   -ms-appearance: none;
 
   &:focus {
    outline: none;
   }
  }
 
  .TextareaStyles {
   box-sizing: border-box;
   width: 100%;
   height: 168px;
   border: 1px solid #C1BBBB;
   border-radius: 6px;
   font-weight: 500;
   font-size: 16px;
   line-height: normal;
   text-align: left;
   color: #1E1E1E;
   padding: 10px 14px;
   resize: none;
 
   &:focus {
    outline: none;
   }
  }
}
.PaymentBox {
 box-sizing: border-box;
  width: 100%;
  height: 58px;
  border: 1px solid #C1BBBB;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
  text-align: left;
  color: #1E1E1E;
  padding: 0 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h5 {
   margin: 0;
   padding: 0;
   font-size: 16px;
   font-weight: 700;
  }
  p {
   margin: 0;
   padding: 0;
   font-size: 16px;
   font-weight: 500;
   color: #1E1E1E;
  }
}
.ShowDeatils {
  font-family: 'Lato';
  font-weight: 700;
  font-size: 16px;
  line-height: 19px; 
  color: #0099EF;
  border: 0;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 6px;
  background: none;
}
.ShowDetailsWrap {
 border: 1px solid #C1BBBB;
  border-radius: 6px;
  .RowBorder {
    border-bottom: 1px solid #C1BBBB;
    padding: 14px;
    h4 {
     font-family: 'Lato';
     font-weight: 600;
     font-size: 16px;
     line-height: 19px;
     color: #1E1E1E;
    }
    h5 {
    font-family: 'Lato';
     font-weight: 500;
     font-size: 14px;
     line-height: 19px;
     color: #1E1E1E;
    }
    .ListTags {
     display: flex;
     flex-wrap: wrap;
     li {      
       background: #EFEFEF;
       border-radius: 4px;
        font-family: 'Lato';
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #1E1E1E;
        padding: 8px 14px;
        margin-right: 10px;
     }
    }
    &:last-child {
     border-bottom: 0 solid #C1BBBB;
    }
  }
  .Flex {
   display: flex;
   justify-content: space-between;
   align-items: center;
   min-height: auto;
  }
}

.citation-payment {
  border: 1px solid #C1BBBB;
  border-radius: 6px;
  height: 58px;
  padding: 18px 14px;
}


.custom-padding {
  padding-top: 0px !important;
}