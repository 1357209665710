.signature-container {
  text-align: center;
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;

  @media (max-width: 600px) {
    padding: 10px;
  }
}

.signature-title {
  font-size: 24px;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    font-size: 18px;
  }
}

.sigCanvas {
  border: 1px solid #000;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  height: auto;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;

  @media (max-width: 600px) {
    font-size: 12px;
  }
}

.button-group {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;

  .black-button {
    background-color: black;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #333;
    }

    @media (max-width: 600px) {
      padding: 8px 16px;
      font-size: 14px;
    }
  }
}

.saved-signature {
  // margin-top: 20px;

  p {
    font-size: 16px;
  }

  .signature-image {
    border: 1px solid #000;
    border-radius: 10px;
    // margin-top: 10px;
    width: 100%;
    max-width: 500px;
    height: auto;
  }
}
